<template>
  <div>
    <Modal v-model="editStatus" class-name="vertical-center-modal" title="客户反馈回复" width="980" @on-ok="commitData" okText="提交" :loading="subLoading" :closable="false" >
      <Form ref="formInline" :label-width="80">
        <Divider orientation="left" dashed>反馈信息 </Divider>
        <div class="feed-m">
          <FormItem label="反馈图片：">
            <div class="cover-img" v-if="data.cover" v-viewer>
              <div class="img-c" v-for="(val,index) in JSON.parse(data.cover)" :key="index">
                <img v-lazy="val" alt="反馈图片" title="反馈图片" />
              </div>
            </div>
          </FormItem>
          <FormItem label="反馈类型：">
            {{data.name}}
          </FormItem>
          <FormItem label="反馈内容：">
            {{data.content}}
          </FormItem>
        </div>
        <Divider orientation="left" dashed><div style="color: #2d8cf0;">反馈回复</div></Divider>
        <FormItem label="" :label-width="0">
          <text-editor ref="textEditor" :newValue="data.reply" :height="300"></text-editor>
        </FormItem>
      </Form>
    </Modal>
  </div>
</template>

<script>
import TextEditor from '@/components/editor/texteditor'
export default {
  name: "edit_feed_back",
  components:{
    TextEditor
  },
  data() {
    return {
      editStatus:false,
      subLoading:true,
      data:{}
    }
  },
  watch:{
    subLoading(e){
      if(!e)
        setTimeout(()=>{
          this.subLoading = true;
        },50)
    }
  },
  methods:{
    init:function(e){
      this.editStatus = true;
      this.data = e;
    },
    commitData:function(){
      let content  = this.$refs.textEditor.getContent();
      if(content.length == 0 || content == ''){
        this.subLoading = false;
        return this.$Message.error('请输入需要发送的内容');
      }
      let _this = this;
      this.requestApi('/adm/replyFeedBack', {content:content,id:this.data.id}).then(function (res) {
        _this.subLoading = false;
        if (res.status == 1) {
          _this.$Message.success(res.msg);
          _this.$emit('editSuccess');
        } else {
          _this.$Message.error(res.msg);
        }
      })
    },
    close:function(){
      this.editStatus = false;
    }
  }
}
</script>

<style lang="less">
.feed-m{
  background-color: #f5f5f5;padding-top:10px;
  .cover-img{
    min-height: 50px;
    .img-c{
      display: block;border:1px solid #eeeeee; width: 50px;height: 50px;margin-right: 10px;line-height: 50px;
      img{
        display: inline-block;margin: auto; max-width: 100%;max-height: 100%;vertical-align: middle; cursor: zoom-in;
      }
    }
  }
}
</style>