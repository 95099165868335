<template>
  <div class="syssetting">
    <div class="breadcrumb-m">
      <div class="left">
        <Breadcrumb>
          <BreadcrumbItem to="/">主控台</BreadcrumbItem>
          <BreadcrumbItem>客户反馈</BreadcrumbItem>
        </Breadcrumb>
      </div>
    </div>
    <div class="ser-m">
      <Form ref="formInline" inline :label-width="70">
        <FormItem label="用户手机">
          <Input type="text" @on-keyup.13="ser" v-model="serData.phone" placeholder="用户手机" maxlength="11"></Input>
        </FormItem>
        <FormItem label="微信昵称">
          <Input type="text" @on-keyup.13="ser" v-model="serData.wx_name" placeholder="微信昵称" maxlength="30"></Input>
        </FormItem>
        <FormItem label="反馈类型">
          <Input type="text" @on-keyup.13="ser" v-model="serData.name" placeholder="反馈类型" maxlength="30"></Input>
        </FormItem>
        <FormItem label="状态">
          <Select style="width: 120px" v-model="serData.status" @on-change="ser">
            <Option :value="0">全部</Option>
            <Option :value="1">待回复</Option>
            <Option :value="2">已回复</Option>
          </Select>
        </FormItem>
        <FormItem label="提交时间">
          <DatePicker type="daterange" placeholder="提交时间" v-model="serData.create_at" style="width: 200px" @on-ok="ser" ></DatePicker>
        </FormItem>
        <FormItem label="回复时间">
          <DatePicker type="daterange" placeholder="回复时间" v-model="serData.reply_at" style="width: 200px" @on-ok="ser" ></DatePicker>
        </FormItem>
        <FormItem :label-width="0">
          <Button type="primary" @click="ser" @keyup.13="ser"><Icon type="ios-search" size="16" />搜索</Button>
        </FormItem>
      </Form>
    </div>
    <Table size="small" :columns="columns" :data="data" :loading="loading">
      <template slot-scope="item" slot="user_id">
        <div class="user-msg">
          <div class="head-img"><viewer :options="{'url': 'data-source'}"><img :src="item.row.head_img+'?x-oss-process=style/headimg'" :data-source="item.row.head_img" /></viewer></div>
          <div class="user-l-item">
            <div>昵称：<span>{{item.row.wx_name||'--'}}</span></div>
            <div>手机：<span>{{item.row.user_phone||'--'}}</span></div>
            <div>性别：<span>{{item.row.gender||'--'}}</span></div>
            <div v-for="(p,k) in item.row.ninfo" :key="k">{{cfg.platform[p.pid]||''}}UID：<span>{{p.num||'--'}}</span></div>
            <div>等级：<span>{{cfg.level[item.row.level_id]||'--'}}</span></div>
            <div>团队业绩：<span>{{item.row.team_achiev||'0.00'}}</span>元</div>
            <div>个人业绩：<span>{{item.row.achiev||'0.00'}}</span>元</div>
            <div>昨日业绩：<span>{{item.row.yes_achiev||'0.00'}}</span>元</div>
            <div>佣金余额：<span>{{item.row.money||'0.00'}}</span>元</div>
          </div>
        </div>
      </template>
      <template slot-scope="item" slot="action">
        <Button class="margin-b5" size="small" type="info" @click="reply(item.index)">回复</Button>
        <Button size="small" type="error" @click="del(item.row.id)">删除</Button>
      </template>
    </Table>
    <div class="footer-m">
      <Page class-name="pagew page-r" :total="total" :current="page" show-total show-elevator show-sizer :page-size="pageSize" :page-size-opts="[10,20,30,50,100,200]" @on-change="changePage" @on-page-size-change="changePageSize"/>
    </div>
    <edit-feedback ref="editFeedRef" @editSuccess="editSuccess"></edit-feedback>
  </div>
</template>
<script>
import editFeedback from '@/views/feedback/edit_feed_back'
export default {
  name: "FeedBackList",
  components:{
    editFeedback
  },
  data(){
    return {
      columns:[
        {title: 'id',key: 'id',minWidth:80},
        {title: '用户信息',slot: 'user_id',minWidth:220},
        {title: '反馈类型',key: 'name',minWidth:150,align: 'center'},
        {title: '提交日期',key: 'create_at',minWidth:140,align: 'center'},
        {title: '显示状态',key: 'is_show',minWidth:120,align: 'center',
          render: (h, params) => {
            return h('div',[
              h('i-switch',
              {
                props: {
                  value:params.row.is_show?1:0, trueValue:1,falseValue:0,size:'large',loading:params.row.loading_show?true:false
                },
                scopedSlots:{
                  open:()=>h('span','显示'),
                  close:()=>h('span','隐藏')
                },
                on: {
                  'on-change': (event) => {
                    params.row.loading_show = 1;
                    this.requestApi('/adm/upFeedBack',{id:params.row.id,val:event}).then((res)=>{
                      if(res.status==1){
                        params.row.is_show = event;
                        this.$Message.success(res.msg);
                      }else this.$Message.error(res.msg);
                      setTimeout(()=>{
                        params.row.loading_show = 0;
                      },300)
                    })
                  }
                }
              })
            ])
          }
        },
        {title: '处理状态',key: 'status',minWidth:70,align: 'center',
          render: (h, params) => {
            return  h('span', {style:{color:params.row.status==1?'red':'green'}}, params.row.status==1?'待回复':'已回复');
          }
        },
        {title: '回复日期',key: 'reply_at',minWidth:140,align: 'center',
          render: (h, params) => {
            return  h('div', params.row.reply_at || '--');
          }
        },
        {title: '操作',slot:'action',width:80,align:'center','fixed':'right'},
      ],
      data:[],
      serData:{name:'',wx_name:'',status:0,reply_at:null,create_at:null},
      page:1,
      pageSize:0,
      total:0,
      loading:false,
      cfg:{platform: {},level:{}}
    }
  },
  created() {
    let query={};
    if(typeof this.$route.query.status!="undefined"){
      this.serData.status=parseInt(this.$route.query.status);
      query.status=this.$route.query.status||0;
    }
    this.$router.push({query});
    this.$store.commit('add_tagsview',this.$route);
    this.init();
  },
  watch:{
    '$route.query':function (query){
      if(query.newpage==1){
        query={'status':query.status||0};
        query.status=parseInt(query.status);
        Object.assign(this.serData,query);
        this.$router.push({query});
        this.$store.commit('add_tagsview',this.$route);
        this.init();
      }
    }
  },
  methods:{
    init:function(){
      let _this = this;
      this.loading = true;
      this.reason = '';
      this.requestApi('/adm/feedBackList',{page_size:this.pageSize,page:this.page,ser_data:this.serData}).then(function (res){
        _this.loading = false;
        if(res.status==1){
          _this.cfg = res.data.cfg;
          _this.total = res.data.page.total;
          _this.pageSize = res.data.page.limit;
          _this.data = res.data.list;
        }
      })
    },
    ser:function(){
      this.page = 1;
      this.init();
    },
    reply:function(index){
      this.$refs.editFeedRef.init(JSON.parse(JSON.stringify(this.data[index])));
    },
    del:function(id){
      let _this = this;
      this.$Modal.confirm({
        title: '提示',
        content: '确定要删除吗？',
        onOk: () => {
          this.requestApi('/adm/delFeedBack', {id:id}).then(function (res) {
            if(res.status == 1){
              _this.$Message.success('删除成功!');
              _this.page = 1;
              _this.init();
            } else
              _this.$Message.error('删除失败!');
          })
        }
      });
    },
    editSuccess:function(){
      this.$refs.editFeedRef.close();
      this.init();
    },
    changePage:function (page){
      this.page = page;
      this.init();
    },
    changePageSize:function (pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.init();
    }
  }
}
</script>
<style lang="less">
.user-msg{
  display: table; width: auto;height: 100px;
  .head-img{
    display: table-cell; width: 40px;height: 40px;border-radius: 20px;vertical-align: middle;
    img{
      width: 40px;height: 40px;border-radius: 20px; cursor: zoom-in;
    }
  }
  .user-l-item{
    display: table-cell; width: auto;vertical-align: middle;padding-left: 5px;
    span{
      color: #2d8cf0;
    }
  }
}
.margin-b5{
  margin-bottom:5px;
}

</style>